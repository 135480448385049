import React from "react"
import Container from "../../components/ui/container"
import {
  HomeSection,
  HomeSectionHeading,
  HomeSectionImage,
  HomeSectionImageBox,
} from "../../components/ui/home-section-components"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const HomeGmdLibrary = (props) => {
  return (
    <HomeSection>
      <Container>
        <HomeSectionHeading>
          Google&nbsp;Material&nbsp;Design Library&nbsp;Development
        </HomeSectionHeading>
        <p>
          As we continue to work on projects using Google Material Design we
          find it best to work with existing library components and help refine
          and create new components to meet the specific needs of each UI
          challenge we face. To this end we have taken on a{" "}
          <a
            href="https://github.com/material-native/material-native"
            target="_blank"
            rel="noopener noreferrer"
            title="Material GitHub"
          >
            Material Native project
          </a>{" "}
          that allows us to benefit from many hours of collaborative work and
          still shape new components in a way that best meets the need of our
          clients.
        </p>
        <p>
          In other words, we don't need to re-invent the wheel, however, we also
          know that one wheel does not fit every case.
        </p>
        <p>
          Our approach will help you save time and money as you build out your
          Material Design UI while still ensuring you get everything you need to
          bring your unique project to life.
        </p>
      </Container>
      <HomeSectionImageBox>
        <StaticImage
          placeholder="blurred"
          layout="constrained"
          src="../../images/home/gmdi.jpg"
          alt="Google Material Design"
          formats={["auto"]}
        />
      </HomeSectionImageBox>
    </HomeSection>
  )
}

export default HomeGmdLibrary
