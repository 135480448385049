import React from "react"
import Container from "../../components/ui/container"
import {
  HomeSection,
  HomeSectionHeading,
  useVideoIndex,
  HomeSectionVideo,
} from "../../components/ui/home-section-components"
import { graphql, useStaticQuery } from "gatsby"

const HomePrototyping = (props) => {
  const data = useStaticQuery(graphql`
    query {
      redwerksAppPrototypeVideos: allFile(
        filter: { relativePath: { glob: "redwerks-app-prototype.*" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)
  const videoIndex = useVideoIndex(data.redwerksAppPrototypeVideos)

  return (
    <HomeSection>
      <Container>
        <HomeSectionHeading>Prototyping</HomeSectionHeading>
        <p>
          Mock-ups for new web and mobile interfaces are a great place to start,
          however, with a static view your experience is limited. The only way
          to really experience a new UI is to see it in action. This doesn't
          mean you need to build your app before you test it. There is a middle
          way.
        </p>
        <p>
          With UI prototyping we can create an authentic user experience where
          you can virtually use your app before development even begins. With
          our approach you are not limited to linked static images to simulate
          usage. Using sophistic software we generate a dynamically rich UI with
          multiple controls within a single screen including fully functioning
          buttons, toggles, and other components with full states, progress, and
          feedback. In short, the experience can be so close to the finished
          product that a test user may not know the difference.
        </p>
        <p>
          This of course can be completed in a fraction of the time of regular
          app development and in the early stages where major UI changes can
          often be delivered&nbsp; the following day. That provides an excellent
          user testing loop to get the best model possible before you start
          building.
        </p>
      </Container>

      <HomeSectionVideo
        name="redwerks-app-prototype"
        videoIndex={videoIndex}
        height={600}
      />
    </HomeSection>
  )
}

export default HomePrototyping
