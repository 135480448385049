import React from "react"
import Container from "../../components/ui/container"
import {
  HomeSection,
  HomeSectionHeading,
  useVideoIndex,
  HomeSectionVideo,
} from "../../components/ui/home-section-components"
import { graphql, useStaticQuery } from "gatsby"

const HomeAppDevelopment = (props) => {
  const data = useStaticQuery(graphql`
    query {
      redwerksWebpageApp6xVideos: allFile(
        filter: { relativePath: { glob: "redwerks-webpage-app6x.*" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)
  const videoIndex = useVideoIndex(data.redwerksWebpageApp6xVideos)

  return (
    <HomeSection>
      <Container>
        <HomeSectionHeading>
          Application&nbsp;development for iOS&nbsp;and&nbsp;Android
        </HomeSectionHeading>
        <p>
          Mobile applications, in most cases, are no longer considered an extra.
          They are expected as part of a modern service offering. As more and
          more people rely on using services away from the home and office,
          traditional offerings are no longer tolerated as the only option.
        </p>
        <p>
          With the rise of Android it's no longer acceptable to create apps that
          only work with Apple devices. This normally requires doubling the work
          to develop for both major platforms. At Redwerks we make this easier
          and more efficient by using technology that allows a single
          development for both platforms. This of course also makes updates much
          easer in the life-cycle of your app.
        </p>
      </Container>

      <HomeSectionVideo
        name="redwerks-webpage-app6x"
        videoIndex={videoIndex}
        height={600}
      />
    </HomeSection>
  )
}

export default HomeAppDevelopment
