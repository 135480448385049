import React from "react"
import styled from "@emotion/styled"
import PartnerBlock from "./ui/partner-block"
import { LayoutFooter } from "./ui/layout-components"
import { superWide } from "../utils/breakpoints"
import { phoneText, phoneFormatted } from "../../meta"

export const ContactRoot = styled(LayoutFooter)`
  padding: 34px 30px;
  background-color: #f4f4f4;
  box-sizing: border-box;
`

const ContactRootBody = styled.div`
  max-width: 1390px;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: root;
  margin-bottom: 40px;

  p {
    margin-bottom: 0.2em;
  }
`
const ContactLeft = styled.div`
  width: 154px;
  max-width: 50%;
  flex: 0 0 auto;
`
const ContactRight = styled.div`
  min-width: 50%;
  max-width: 350px;
  flex: 1 1 auto;
`

const PartnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  @media (min-width: 750px) and (max-width: ${superWide - 1}px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Contact = (props) => {
  return (
    <ContactRoot>
      <ContactRootBody>
        <h2>Contact</h2>
        <ContactContainer>
          <ContactLeft>
            <p>Toll Free</p>
            <p>{phoneText}</p>
            <p>({phoneFormatted})</p>
          </ContactLeft>
          <ContactRight>
            <p>Hours of Operation</p>
            <p>
              9:00 - 17:00 PST. Expect emails and after-hours calls returned
              within one business day
            </p>
          </ContactRight>
        </ContactContainer>
        <PartnerContainer>
          <PartnerBlock
            variant="light"
            name="Gregory Thomas"
            avatar="gregory-california"
            location="Southern California"
            map="southern-california"
          />
          <PartnerBlock
            variant="dark"
            name="Daniel Friesen"
            avatar="daniel-bc"
            location="British Columbia"
            map="british-columbia"
          />
        </PartnerContainer>
      </ContactRootBody>
    </ContactRoot>
  )
}

export default Contact
