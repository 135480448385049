import React from "react"
import styled from "@emotion/styled"
import { superWide } from "../../utils/breakpoints"

const ContainerRoot = styled.div`
  margin: 0 auto;
  padding: 0 34px;
  max-width: 820px;

  @media (min-width: 820px) {
    padding: 0 80px;
  }
`
const ContainerContent = styled.div``

const Container = ({ children }) => (
  <ContainerRoot>
    <ContainerContent>{children}</ContainerContent>
  </ContainerRoot>
)

export default Container
