import React, { useMemo, useRef, useLayoutEffect } from "react"
import { useInView } from "react-intersection-observer"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { useEffect } from "react"
import { useNetworkStatus } from "react-adaptive-hooks/network"

export const HomeSection = styled.div`
  padding-top: 40px;
`

export const HomeSectionHeading = styled.h2`
  text-align: center;
`

export const HomeSectionImageBox = styled.div`
  margin: 34px auto;
  padding: 0 34px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const useVideoIndex = (videoList) => {
  return useMemo(() => {
    const videos = Object.create(null)
    for (const edge of videoList.edges) {
      videos[edge.node.relativePath] = edge.node.publicURL
    }
    return videos
  }, [videoList])
}

const HomeSectionVideoContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  border-bottom: 1px solid #d6d6d6;
  height: ${(props) => `${props.height}px`};
  width: 100%;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const types = [
  { mime: "video/mp4", ext: "mp4" },
  { mime: "video/webm", ext: "webm" },
  { mime: "video/ogg", ext: "ogv" },
]

export const HomeSectionVideo = (props) => {
  const { name, videoIndex, height } = props

  const videoRef = useRef()
  const [ref, inView] = useInView({
    rootMargin: "-50px 0px",
  })

  const { effectiveConnectionType, saveData } = useNetworkStatus()
  const shouldPreload = useMemo(() => {
    if (["slow-2g", "2g", "3g"].includes(effectiveConnectionType)) return false
    if (saveData) return false
    return true
  }, [effectiveConnectionType, saveData])

  const sources = types
    .map((type) => ({ ...type, src: videoIndex[`${name}.${type.ext}`] }))
    .filter((source) => source.src)

  useEffect(() => {
    videoRef.current.autoPlay = false
    videoRef.current.preload = shouldPreload ? "auto" : "none"
  }, [shouldPreload])

  useEffect(() => {
    if (inView) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [inView])

  return (
    <HomeSectionVideoContainer ref={ref} height={height}>
      <video ref={videoRef} autoPlay preload="auto" loop muted playsInline>
        {sources.map((source) => (
          <source key={source.ext} type={source.mime} src={source.src} />
        ))}
      </video>
    </HomeSectionVideoContainer>
  )
}
