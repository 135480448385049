import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useMemo } from "react"

export const PartnerBlockRoot = styled.footer`
  h3 {
    margin-bottom: 5px;
  }

  img {
    margin: 0;
  }
`

const MapImageBlock = styled.div`
  position: relative;
`

const MapImg = styled(GatsbyImage)`
  width: 100%;
  min-width: 300px;
  max-width: 690px;
`

const AvatarImg = styled(GatsbyImage)`
  border: 6px solid #8e8e8e;
  border-radius: 50%;
`

const MapCornerBlock = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
`

const MapNameBlock = styled.div`
  font-weight: 500;
`
const LightMapNameBlock = styled(MapNameBlock)`
  color: rgba(0, 0, 0, 0.8);
`
const DarkMapNameBlock = styled(MapNameBlock)`
  color: #ffffff;
`

const findImage = (files, dir, name) => {
  const edge = files.edges.find(
    (edge) => edge.node.relativePath === `${dir}/${name}.png`
  )

  return edge && edge.node.childImageSharp.gatsbyImageData
}

let PartnerBlock = (props) => {
  const { variant = "light", map, avatar, name, location } = props
  const data = useStaticQuery(graphql`
    {
      maps: allFile(
        filter: { relativePath: { glob: "maps/*" }, extension: { eq: "png" } }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(width: 690, layout: CONSTRAINED)
            }
          }
        }
      }
      avatars: allFile(
        filter: {
          relativePath: { glob: "avatars/*" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(width: 90, height: 90, layout: FIXED)
            }
          }
        }
      }
    }
  `)
  const mapImg = useMemo(() => findImage(data.maps, "maps", map), [data, map])
  const avatarImg = useMemo(
    () => findImage(data.avatars, "avatars", avatar),
    [data, avatar]
  )

  const VariantMapNameBlock =
    variant === "dark" ? DarkMapNameBlock : LightMapNameBlock

  return (
    <PartnerBlockRoot>
      <h3>{location}</h3>
      <MapImageBlock>
        {mapImg && (
          <MapImg width={690} image={mapImg} alt={`Map of ${location}`} />
        )}
        <MapCornerBlock>
          {avatarImg && (
            <AvatarImg
              width={90}
              height={90}
              image={avatarImg}
              alt={`${name}'s avatar`}
            />
          )}
          <VariantMapNameBlock variant={variant}>{name}</VariantMapNameBlock>
        </MapCornerBlock>
      </MapImageBlock>
    </PartnerBlockRoot>
  )
}
PartnerBlock = React.memo(PartnerBlock)

export default PartnerBlock
