import React from "react"
import Container from "../../components/ui/container"
import {
  HomeSection,
  HomeSectionHeading,
  useVideoIndex,
  HomeSectionVideo,
} from "../../components/ui/home-section-components"
import { graphql, useStaticQuery } from "gatsby"

const HomeUiDesign = (props) => {
  const data = useStaticQuery(graphql`
    query {
      redwerksWebUiVideos: allFile(
        filter: { relativePath: { glob: "redwerks-web-ui-web2c6.*" } }
      ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
      }
    }
  `)
  const videoIndex = useVideoIndex(data.redwerksWebUiVideos)

  return (
    <HomeSection>
      <Container>
        <HomeSectionHeading>User&nbsp;Interface design</HomeSectionHeading>
        <p>
          At Redwerks we specialize in User Interface Design. This includes
          conceptual design for your new web software or mobile app device,
          functional front-end components and full software integration. We can
          fit into any role that best suites your needs.
        </p>
        <p>
          We are well versed in Google Material Design language and highly
          recommend it for User Interfaces for both web and mobile. We also
          build front-end library material components to meet any custom need.
        </p>
      </Container>

      <HomeSectionVideo
        name="redwerks-web-ui-web2c6"
        videoIndex={videoIndex}
        height={600}
      />
    </HomeSection>
  )
}

export default HomeUiDesign
