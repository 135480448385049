import React from "react"
import styled from "@emotion/styled"
import Container from "../../components/ui/container"
import { graphql, useStaticQuery } from "gatsby"
import {
  HomeSection,
  HomeSectionImage,
  HomeSectionImageBox,
} from "../../components/ui/home-section-components"
import { StaticImage } from "gatsby-plugin-image"

const IntroSection = styled(HomeSection)`
  padding-bottom: 80px;
  background-color: #f4f4f4;
`

const IntroHeading = styled.h1`
  font-size: 50px;
  text-align: center;
`

const HomeIntro = (props) => {
  return (
    <IntroSection>
      <Container>
        <IntroHeading>Making the&nbsp;web more&nbsp;human</IntroHeading>
        <p>
          <b>
            Our mission is to create web applications that everyone can
            participate in building, from developer to designer to client to
            user.
          </b>
        </p>
        <p>
          Redwerks is a web design and web software development company. We
          specialize in User Interface design (Including Google Material
          Design). We create web projects that focus on the human user. We start
          with your business needs and your customers' way of thinking, and
          ensure your message is clearly formed and properly received. Through
          our client work, we've created tools that help other companies design
          and develop websites, and manage their projects.
        </p>

        <HomeSectionImageBox>
          <StaticImage
            loading="eager"
            placeholder="blurred"
            layout="constrained"
            src="../../images/home/google-home-ai.png"
            alt="Google Home AI"
            formats={["auto"]}
          />
        </HomeSectionImageBox>

        <h2>
          Artificial Intelligence Design for Google Home (Virtual Assistant) and
          Amazon Alexa
        </h2>
        <p>
          As Artificial Intelligence grows in complexity it's practical
          applications continue to emerge. Not too long ago mobile apps were
          considered a novelty and now they exist in the center of work and
          personal life for many people. The same is now happening for AI
          applications on phones and dedicated home devices.
        </p>
        <p>
          It's estimated that at least 200 million devices will have access to
          voice assistance and Amazon will sell 60 million Echo devices by 2020.
          It's already much easier to say "Hey Google, add a meeting to my
          calendar for this Friday at 10 am", then to pull up a calendar
          application to schedule. As natural language processing progresses it
          will be the natural choice for many.
        </p>
        <p>
          We want to work with you to get your content into offices and homes
          across the world. We currently offer development for Alexa (Amazon
          Echo, Dot and Tap) and Google AI.
        </p>
      </Container>
    </IntroSection>
  )
}

export default HomeIntro
