import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { LayoutMain } from "../components/ui/layout-components"
import HomeIntro from "../page-components/home/intro"
import HomeUiDesign from "../page-components/home/ui-design"
import HomeAppDevelopment from "../page-components/home/app-development"
import HomePrototyping from "../page-components/home/prototyping"
import HomeGmdLibrary from "../page-components/home/gmd-library"
import Helmet from "react-helmet"
import organization from "../../organization"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organization, null, 2)}
      </script>
    </Helmet>
    <LayoutMain>
      <HomeIntro />
      <HomeUiDesign />
      <HomeAppDevelopment />
      <HomePrototyping />
      <HomeGmdLibrary />
    </LayoutMain>
    <Contact />
  </Layout>
)

export default IndexPage
